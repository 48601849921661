.page-builder__wrapper {
    .page-builder__content-block:not(.--first) {
        &:not(.--last) {
            .horizontal__line {
                border-bottom: .5rem solid color(Dane, Gold);
            }
        }
    }
}
.back-to-top {
    position: fixed;
    display: flex;
    z-index: 2;
    bottom: 2rem;
    left: 2rem;
    font-size: 1.5rem;
    background-color: color(Dane, Red);
    text-transform: uppercase;
    color: color(Dane, White);
    padding: 1rem 3.6rem 1rem 1rem;
    font-weight: 700;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="8.75" viewBox="0 0 320 512"><path fill="#{color(Dane, White)}" d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8H288c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/></svg>');
    background-repeat: no-repeat;
    background-size: 1.4rem;
    background-position: center right 1rem;

    @include hover() {
        background-color: darken(color(Dane, Red), 10%);
    }
}

.fullpage-wrapper {
    .--content {
        h2 {
            font-size: 3.2rem;
        }
        p {
            font-size: 1.8rem;
            line-height: 2.2rem;
        }
    }
    .--animation {
        &.--slide-from-right {
            text-align: left;
            position:initial;
            @include breakpoint(medium) {
                padding: 0 0 0 4rem;
            }
        }

        &.--slide-from-left {
            text-align: right;
            position:initial;
        }
    }

    .fp-overflow {
        height: 100%;
    }
}



@keyframes SlideFromRight {
    0% {
        transform: translateX(40rem);
        opacity: 0;
        filter: blur(.5rem);
    }
    50% {
        transform: translateX(40rem);
        opacity: 0;
        filter: blur(.5rem);
    }
    90% {
        filter: blur(.5rem);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
    }
}

@keyframes SlideFromLeft {
    0% {
        transform: translateX(-40rem);
        opacity: 0;
        filter: blur(.5rem);
    }
    50% {
        transform: translateX(-40rem);
        opacity: 0;
        filter: blur(.5rem);
    }
    90% {
        filter: blur(.5rem);
    }
    100% {
        transform: translateX(0);
        opacity: 1;
        filter: blur(0);
    }
}

.fp-enabled {
    header .header__container--wrapper .header__container > a {
        position: absolute;
        left: 0;
        top: 0;
        height: 13rem;
        width: 46rem;
    }
}
