.product__quick-buy--wrapper {
    border-top: .1rem solid color(Gray, Gray-300);
    margin: 2rem 0;
    padding: 2rem 0 0;

    @include breakpoint(medium) {
        margin: 5rem 0;
        padding: 5rem 0 0;
    }

    .--header {
        display: flex;
        margin: 0 0 5rem;

        h2 {
            text-align: center;
        }
    }

    .mobile__scrollable {
        @include breakpoint(small only) {
            overflow-y: scroll;
            width: 100%;
        }

        table {
            width: 100%;
        }
    }

    .--actions {
        margin: 2rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
    }

    .quick-buy__success {
        display: block;
        margin: 1rem 0 0;
        text-align: center;
    }
}
