.homepage__fullscreen-hero--block-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 14.4rem;

    @include breakpoint(medium) {
        padding-top: 7.5rem;
    }

    .top__block {
        flex: 1;

        > div {
            height: 100%;
            background-size: cover;
            background-position: center center;
            overflow: hidden;
            display: flex;
            @include breakpoint(small only) {
                padding-top: 100%;
            }
        }
    }
    .mobile__block {
        padding: 2.8rem 1rem;

        .grid-x {
            > div {
                &:last-child {
                    display: none;
                }
            }
        }
    }
    .bottom__block {
        display: flex;
        flex: 0 0 20%;
        gap: 2rem;

        @include breakpoint(small only) {
            flex-flow: column;
            height: 100%;
            flex: 1;
        }

        > a {
            flex: 1;
            position: relative;

            > img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .--overlay {
                transition: width .5s ease-in-out;
                position: absolute;
                z-index: 1;
                top: 30%;
                bottom: 30%;
                width: 100%;
                right: 0;
                background: linear-gradient(to right, rgba(0,0,0,0) 0% ,#171615 40%);
                overflow: hidden;
                @include breakpoint(small only) {
                    top: 58%;
                    bottom: 0;
                }

                &:before {
                    content: '';
                    display: block;
                    height: 800px;
                    width: 75%;
                    background: repeating-linear-gradient(90deg, #171615, #171615 5px, transparent 0, transparent 10px);
                    top: -300px;
                    right: 0;
                    position: absolute;
                    z-index: -1;
                    transform: rotate(45deg);
                }

                .--inner {
                    transition: opacity .5s ease-in-out;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-flow: column;
                    height: 100%;

                    > span {
                        display: block;
                        color: color(base, White);
                        line-height: 1;

                        &:first-child {
                            font-weight: 900;
                            font-size: 2.8rem;
                            position: relative;
                        }

                        &:last-child {
                            font-size: 1.4rem;
                            line-height: 2rem;
                        }
                    }
                }
            }
        }
    }
    .--content p, .--content h2 {
        @include breakpoint(small only) {
            color: color(Dane, Black) !important;
        }
    }
    .--text-block-padding{
        .--content{
            padding:1.6rem;
        }
    }
}
