.homepage__style--block-wrapper {
    .homepage__style__container {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(medium) {
            grid-template-columns: repeat(4, 1fr);
            &.grid-cols-1 {
                grid-template-columns: repeat(1, 1fr);
            }
            &.grid-cols-2 {
                grid-template-columns: repeat(2, 1fr);
            }
            &.grid-cols-4 {
                grid-template-columns: repeat(4, 1fr);
            }
            &.grid-cols-6 {
                grid-template-columns: repeat(6, 1fr);
            }
        }

        .--block {
            position: relative;
            display: flex;

            .--title {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                color: color(base, White);
                text-align: center;
                font-weight: 700;
                text-shadow: 0 .1rem .3rem rgba(0, 0, 0, .5);
                padding: 2rem;
                line-height: 1;
                font-size: 1.8rem;

                @include breakpoint(medium) {
                    font-size: 3.4rem;
                }
            }

            .--overlay {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                background: rgba(48, 48, 48, .4);
                overflow: hidden;
                width: 100%;
                height: 0;
                transition: .5s ease;
            }

            @include hover() {
                .--overlay {
                    height: 100%;
                }
            }
        }
    }
}
